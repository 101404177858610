import * as React from "react"
import { SettingsProvider } from "../components/page-layout/nav/settings"
import { UserProvider } from "../components/utils/UserContext"
import AwsRum from "../components/page-layout/aws-rum"
import Seo from "../components/page-layout/seo"
import Button from "../components/buttons/button"
import SiteHeaderGeneric from "../components/page-layout/header/site-header-generic"

const ForbiddenPage = () => (
  <>
    <UserProvider>
      <SettingsProvider>
        <AwsRum />
        <Seo title="Access Forbidden" />
        <SiteHeaderGeneric pageTitle="403: Access Forbidden" />
        <main className="px-10 py-6 text-center">
          <p className="mb-3">
            We're sorry, you do not have the appropriate permissions to access this
            page.
          </p>
          <Button
            color="primary"
            url="/"
            additionalAttr={{ "data-context": "forbidden page" }}
            additionalClasses={["flex mx-auto mt-2 md:w-1/4"]}
          >
            Return Home
          </Button>
        </main>
      </SettingsProvider>
    </UserProvider>
  </>
)

export default ForbiddenPage
